<template>
    <div class="navbar">
        <div class="navbar-start">
            <div class="dropdown">
                <div tabindex="0" role="button" class="btn btn-ghost lg:hidden">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M4 6h16M4 12h8m-8 6h16" />
                    </svg>
                </div>
                <ul tabindex="0"
                    class="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
                    <li><a href="/">Home</a></li>
                    <li><a href="/about-me">About me</a></li>
                </ul>
            </div>
            <div class="avatar">
              <a class="btn btn-ghost text-xl" href="/">
                <img src="@/images/logo.png" class=" h-16 w-16" alt="logo">
              </a>
            </div>
        </div>
        <div class="navbar-center hidden lg:flex">
            <ul class="menu menu-horizontal px-1">
                <li><a href="/">Home</a></li>
                <li><a href="/about-me">About me</a></li>
                
            </ul>
        </div>
        <div class="navbar-end">
            <p class="thermometer-btn py-2 px-4 rounded text-white " :class="temperatureClass" id="temperature-btn">Faro
                {{ temperature }} °C</p>
        </div>
    </div>
</template>
<style scoped>
.header {
    position: absolute;
    z-index: 30;
    top: 0;
    left: 0;
    padding: 1rem 1.5rem;
    background-color: #E9F7EF;
    border-radius: 0 0 10px 10px;
    /* Rounded corners at the bottom */
}

.header h3 {
    font-size: 1.125rem;
    color: #2C3E50;
}

/* Thermometer button styling */
.thermometer-btn {
    display: inline-block;
    padding: 0.5rem 1rem;
    margin-left: 1rem;
    /* Space between the name and the button */
    border-radius: 5px;
    color: #FFFFFF;
    /* White text */
    transition: background-color 0.3s ease;
    /* Smooth transition for color changes */
}

/* Different colors for temperature ranges */
.thermometer-btn.cold {
    background-color: #3498DB;
    /* Sky Blue for cold temperatures */
}

.thermometer-btn.cool {
    background-color: #2ECC71;
    /* Emerald Green for cool temperatures */
}

.thermometer-btn.mild {
    background-color: #F39C12;
    /* Bright Orange for mild temperatures */
}

.thermometer-btn.warm {
    background-color: #FF6F61;
    /* Vibrant Coral for warm temperatures */
}

.thermometer-btn.hot {
    background-color: #E74C3C;
    /* Red for hot temperatures */
}

/* Back button styling */
.back-btn {
    background-color: #2C3E50;
    /* Dark color for the back button */
    color: #FFFFFF;
    /* White text */
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* Smooth transition for color changes */
}

.back-btn:hover {
    background-color: #1A252F;
    /* Slightly darker on hover */
}
</style>

<script>
export default {
    name: 'HeaderComponent',
    props: ['temperature'],
    computed: {
        temperatureClass() {
            if (this.temperature < 5) {
                return 'cold';
            } else if (this.temperature >= 5 && this.temperature <= 15) {
                return 'cool';
            } else if (this.temperature >= 16 && this.temperature <= 24) {
                return 'mild';
            } else if (this.temperature >= 25 && this.temperature <= 30) {
                return 'warm';
            } else {
                return 'hot';
            }
        },
    },
}
</script>