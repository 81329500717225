<template>
    <footer class="footer p-10 bg-base-200 text-base-content flex items-center justify-center">
        <div class="mt-4 flex justify-center space-x-4">
            <a href="mailto:rsribeiroit@gmail.com" class="link link-hover">
              <i class="fas fa-envelope fa-2x"></i>
            </a>
            <a href="https://www.linkedin.com/in/ricardo-s-ribeiro/" class="link link-hover" target="_blank">
              <i class="fab fa-linkedin fa-2x"></i>
            </a>
          </div>
    </footer>
  </template>
  
  
  <script setup>

  </script>
  
  <style scoped>
  /* Custom styles to maintain your design */
  .footer {
    background-color: #e9f7ef; /* Example background color */
    color: #000; /* Example text color */
  }
  
  .footer-title {
    font-weight: bold;
    font-size: 1.25rem;
  }
  
  .link-hover:hover {
    color: #007bff; /* Example hover color */
  }
  </style>