<template>
    <div class="my-skills pt-14 pb-14 flex flex-col md:flex-row gap-2 md:gap-10 justify-center items-center mb-14 md:mb-20">
       <div class="my-skills-text flex-grow w-[90%] md:order-2">
           <div class="contact-form">
               <h2 class="text-2xl sm:text-3xl lg:text-4xl mb-6 text-center text-2ecc71">Contact Me</h2> <!-- Added this line -->
               <div v-if="!emailSent">
                   <form @submit.prevent="submitForm" class="form-container">
                   <div class="form-group">
                       <label for="email">Your Email</label>
                       <input v-model="email" id="email" placeholder="Your Email" required class="form-input" />
                   </div>
                   <div class="form-group">
                       <label for="message">Your Message</label>
                       <textarea v-model="message" id="message" placeholder="Your Message" required class="form-input"></textarea>
                   </div>
                   <button type="submit" class="submit-button">Send</button>
                   </form>
               </div>
               <div v-else class="email-sent-notification">
                   <p class="text-center text-2ecc71">Email was sent</p>
               </div>
           </div>
       </div>
       
   </div>
</template>

<script>
export default {
   name: 'MyJourney',
   data() {
       return {
           emailSent: false, // New data property to track form submission status
           email: '', // Add data properties to bind with form inputs
           message: '', // Add data properties to bind with form inputs
       };
   },
   methods:{
       async submitForm() {
           try {
               const response = await fetch('/contact', {
               method: 'POST',
               headers: {
                   'Content-Type': 'application/json',
               },
               body: JSON.stringify({
                   email: this.email,
                   message: this.message,
               }),
               });
               if (response.ok) {
                       this.emailSent = true;
                       setTimeout(() => {
                           this.emailSent = false;
                           this.email = ''; // Reset the email field
                           this.message = ''; // Reset the message field
                       }, 3000);
                   } else {
                       const errorText = await response.text();
                       console.error('Failed to send email:', errorText);
                   }
           } catch (error) {
               console.error('Error:', error);
           }
       },
   }
};
</script>

<style scoped>
.my-journey-image {
   box-shadow: -15px 15px 0px #A2C5C0;
}
.email-sent-notification {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   padding: 20px;
   background-color: #E9F7EF; /* Adjust background color as needed */
   border-radius: 10px; /* Adjust border-radius as needed */
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Adjust box-shadow as needed */
}


@keyframes fadeIn {
   from {
       opacity: 0;
   }
   to {
       opacity: 1;
   }
}

@keyframes fadeOut {
   from {
       opacity: 1;
   }
   to {
       opacity: 0;
   }
}
.contact-form {
 max-width: 500px;
 margin: 0 auto;
 padding: 20px;
 box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
 border-radius: 10px;
 background-color: #E9F7EF; /* Background color */
}

.form-container {
 display: flex;
 flex-direction: column;
 gap: 20px;
}

.form-group {
 display: flex;
 flex-direction: column;
}

.form-group label {
 margin-bottom: 10px;
 font-weight: bold;
 color: #2ECC71; /* Label color */
}

.form-input {
 padding: 10px;
 border: 1px solid #3498DB; /* Border color */
 border-radius: 5px;
 font-size: 16px;
}

.submit-button {
 padding: 10px 20px;
 border: none;
 border-radius: 5px;
 background-color: #2ECC71; /* Button background color */
 color: #fff; /* Button text color */
 font-size: 16px;
 cursor: pointer;
}

.submit-button:hover {
 background-color: #28B463; /* Button hover background color */
}






</style>