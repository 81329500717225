<template>
  <div :id="itemId" class="carousel-item w-full h-auto">
    <div class="short-bio flex flex-col md:flex-row gap-10 md:gap-16 justify-center items-center mb-3 ml-2">
      <div :class="[`short-bio-${bioType}`, 'w-[70%]', 'md:max-w-[300px]' , 'max-w-[230px]']">
        <figure>
          <img :src="imageSrc" :alt="imageAlt" width="300" class="target" @load="$emit('image-loaded')">
        </figure>
      </div>
      
      <div class="short-bio-text flex-grow w-[90%]  flex flex-col">
        <h1 class="text-2xl sm:text-4xl lg:text-5xl lg:mb-6 sm:mb-3">{{ title }}</h1>
          <div class="short-bio-text flex-grow w-[90%]">
            <p class="md:text-base lg:text-lg !leading-relaxed" v-html="htmlContent"></p>
            <div v-if="copywritter" class="wrapper md:text-base lg:text-lg !leading-relaxed">
              <span>{{ currentText }}</span>
            </div>
          </div>
      </div>
    </div>
    <div class="overlay">
      <div class="name-display ">
        <span v-for="(char, index) in loadTitle" :key="index" :style="{ '--i': index + 1 }">{{ char }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  itemId: String,
  imageSrc: String,
  imageAlt: String,
  title: String,
  currentText: String,
  htmlContent: String,
  loadTitle: String,
  bioType: String,
  copywritter: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(['image-loaded']);


</script>

<style scoped>
.wrapper {
  animation: slide-text 20s steps(1, end) infinite;
  display: inline-block;
  position: relative;
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #E9F7EF;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 1;
}

.overlay.animate {
  opacity: 1;
}

.name-display {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 1;
  z-index: 2;
  text-transform: uppercase;
}

.name-display.show {
  position: relative;
  display: inline-block;
  color: black;
  text-transform: uppercase;
}

.name-display.show span {
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  font-size: 32px;
  color: black;
  text-transform: uppercase;
  animation: waviy 2s infinite;
  animation-delay: calc(.04s * var(--i));
}



@keyframes waviy {

  0%,
  40%,
  100% {
    transform: translateY(0)
  }

  20% {
    transform: translateY(-20px)
  }
}


</style>