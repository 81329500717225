<template>
    <div class="carousel w-full ">
        <CarouselItem itemId="item1" :imageSrc="ricardoImage" imageAlt="selfie" title="Hello there!"
            :currentText="currentText"
            htmlContent="Creating and deploying websites as well has automating task to help you in your workflow (so you can have more time) thats what I aim to do.<br />I am  always on the lookout for <span class='font-bold'> remote </span>job opportunities.<br />I excel <span class='font-bold'> in developing solutions</span> that help your businesses grow.<br />
            Down Below are a few company's I have worked with.
            "
            @animate-overlay="animateOverlay" loadTitle="me" bioType="image" :copywritter="false"  @load="checkLoaded" />
        <CarouselItem itemId="item2" :imageSrc="contentservLogo" imageAlt="contentserv_logo" title=""
            :currentText="currentText" htmlContent="ContentServ (PIM/DAM): Successfully resolved bugs and optimized PHP code within a legacy codebase. Adapted to SVN/Git transition. Collaborated effectively in agile environment using daily stand-ups and Xdebug for debugging. Contributed to platform's continued success and demonstrated strong problem-solving skills."
            @animate-overlay="animateOverlay" loadTitle="Contentserv"
            bioType="contentserv"
            @load="checkLoaded"/>



        <CarouselItem itemId="item3" :imageSrc="plmLogo" imageAlt="plm_logo" title="" :currentText="currentText"
            htmlContent=" As a versatile web developer at PLM, I've worked on projects across various industries, including Prestashop e-commerce for a national bank. I collaborated closely with in-house teams to optimize project timelines and am proficient in using large-scale design files like Figma and Adobe XD.
                             Additionally, I've created custom scripts (crons) to streamline data export and improve efficiency."
            @animate-overlay="animateOverlay" loadTitle="Plm" bioType="plm"
            @load="checkLoaded" />


        <CarouselItem itemId="item4" :imageSrc="waynextLogo" imageAlt="waynext_logo" title="" :currentText="currentText"
            htmlContent="Developed web projects for renowned clients, mastered proprietary PHP framework, and demonstrated adaptability in a fast-paced agency environment."
            @animate-overlay="animateOverlay" loadTitle="Waynext" bioType="waynext"  @load="checkLoaded" />



        <CarouselItem itemId="item5" :imageSrc="clubefashionLogo" imageAlt="ClubeFashion" title=""
        :currentText="currentText"
        htmlContent="  Successfully modernized legacy platform, transitioning to Symfony MVC and implementing TWIG templates.<br/>
                         Overcame technical challenges and improved codebase for enhanced maintainability and scalability."
        @animate-overlay="animateOverlay" loadTitle="Clubefashion" bioType="clubefashion"  @load="checkLoaded" />
       
    </div>


    <div class="flex w-full justify-center gap-2 py-2 lg:mt-12 md:mt-4 grid-container">
        <a @click="event => animateOverlay(event, 'item1', '#AEDFF7')" class="h-16 w-16 flex-initial m-2 hover-button">
          <img src="@/images/Ricardo_Ribeiro.jpg" alt="Me" class="w-full h-auto "  />
        </a>
        <a @click="event => animateOverlay(event, 'item2', '#00c2ff')" class="h-16 w-16 flex-initial m-2 hover-button">
          <img src="@/images/companys/contentserv_logo-removebg.png" alt="Contentserv_logo" class="w-full h-auto" />
        </a>
        <a @click="event => animateOverlay(event, 'item3', '#556ca9')" class="h-16 w-16 flex-initial m-2 hover-button">
            <img src="@/images/companys/plm_logo-removebg.png" alt="Plm_logo" class="w-full h-auto" />
        </a>
        <a @click="event => animateOverlay(event, 'item4', '#4A102D')" class="h-16 w-16 flex-initial m-2 hover-button">
          <img src="@/images/companys/waynext_logo.png" alt="Waynext_logo" class="w-full h-auto" />
        </a>
        <a @click="event => animateOverlay(event, 'item5', '#f26522')" class="h-16 w-16 flex-initial m-2 hover-button">
          <img src="@/images/companys/clubefashion.png" alt="Clubefashion_logo" class="w-full h-auto " />
        </a>
      </div>

</template>


<script setup>
import { ref, onMounted, nextTick, onBeforeUnmount } from 'vue';
import CarouselItem from '../about/biographycomponents/carouselitem.vue';


const isLoaded = ref(false);

// Use require function to dynamically load images
const ricardoImage = require('@/images/Ricardo_Ribeiro.jpg');
const contentservLogo = require('@/images/companys/contentserv_logo.png');
const waynextLogo = require('@/images/companys/waynext_logo.png');
const plmLogo = require('@/images/companys/plm_logo.png');
const clubefashionLogo = require('@/images/companys/clubefashion_logo-scaled.png');


const checkLoaded = () => {
    nextTick(() => {
        isLoaded.value = true;
        setCarouselItemHeight();
    });
};

const setCarouselItemHeight = () => {
    nextTick(() => {
        const container = document.querySelector('.carousel');
        const allSlides = container.querySelectorAll('.carousel-item');

        // Remove height restrictions
        allSlides.forEach(slide => {
            slide.style.height = 'auto';
        });

        let maxHeight = 0;
        // Calculate the height of the tallest carousel item
        allSlides.forEach(slide => {
            slide.classList.remove('hidden');
            const slideHeight = slide.offsetHeight;
            if (slideHeight > maxHeight) {
                maxHeight = slideHeight;
            }
        });

        // Set the height of all carousel items to the height of the tallest item
        allSlides.forEach(slide => {
            slide.style.height = `${maxHeight}px`;
        });

        // Hide all slides except the first one
        allSlides.forEach((slide, index) => {
            if (index !== 0) {
                slide.classList.add('hidden');
            }
        });
    });
};


onMounted(() => {
    // Ensure images are loaded before setting the height
    const images = document.querySelectorAll('.carousel-item img');
    let loadedImagesCount = 0;

    images.forEach((img) => {
        img.addEventListener('load', () => {
            loadedImagesCount++;
            if (loadedImagesCount === images.length) {
                setCarouselItemHeight();
                isLoaded.value = true;
            }
        });
    });

    window.addEventListener('resize', setCarouselItemHeight);
});



const hexToRgb = (hex) => {
    let bigint = parseInt(hex.slice(1), 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
    return { r, g, b };
};

const animateOverlay = (event, targetId, hexColor) => {
    event.preventDefault(); // Prevent default anchor behavior

    // Get the target element by its ID
    const targetElement = document.getElementById(targetId);
    const container = document.querySelector('.carousel');


    // Hide all slides except the target one
    const allSlides = container.querySelectorAll('.carousel-item');
    allSlides.forEach(slide => {
        if (slide !== targetElement) {
            slide.classList.add('hidden');
        }
    });

    // Calculate the horizontal position of the target element
    const targetPosition = targetElement.offsetLeft - container.offsetLeft;

    // Scroll the carousel container to the target position
    container.scrollTo({ left: targetPosition, behavior: 'smooth' });

    // Show the target slide
    targetElement.classList.remove('hidden');

    // Get the overlay and name display elements within the target element
    const overlay = targetElement.querySelector('.overlay');
    const nameElement = targetElement.querySelector('.name-display');

    if (overlay && nameElement) {
        overlay.classList.add('animate');
        nameElement.classList.add('show');

        const baseColor = hexToRgb(hexColor);
        const spans = nameElement.querySelectorAll('span');
        spans.forEach((span, index) => {
            const decrement = 10; // Amount to darken each subsequent span
            const r = Math.max(baseColor.r - index * decrement, 0);
            const g = Math.max(baseColor.g - index * decrement, 0);
            const b = Math.max(baseColor.b - index * decrement, 0);
            span.style.color = `rgb(${r}, ${g}, ${b})`;
        });

        setTimeout(() => {
            nameElement.classList.remove('show');
            overlay.classList.remove('animate');
        }, 1100); // Duration of the animation
    }
};
</script>

<style scoped>
.carousel {
    align-items: stretch;
}

.carousel-item {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    align-items: stretch;
}

.hover-button {
    position: relative;
    display: inline-block;
    overflow: hidden;
    border-radius: 8px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.grid-container {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(5, 1fr); /* 2 columns on smaller screens */
  }
  
  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: repeat(4, 1fr); /* 2 columns on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .grid-container {
      grid-template-columns: repeat(3, 1fr); /* 1 column on very small screens */
    }
  }

.hover-button img {
    display: block;
    width: 100%;
    height: auto;
    transition: transform 0.3s;
}

.hover-button:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.hover-button:hover img {
    transform: scale(1.05);
}


.hover-button::before {
    content: '';
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
      to right, 
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0.0) 100%
    );
    animation: shine 3s infinite;
  }
  
  @keyframes shine {
    0% {
      top: -110%;
      left: -210%;
      opacity: 0;
    }
    50% {
      top: -30%;
      left: -30%;
      opacity: 1;
    }
    100% {
      top: -110%;
      left: 100%;
      opacity: 0;
    }
  }


</style>
