<template>

<div class="flex flex-col sm:flex-row gap-8 my-3 sm:my-8">
    <div class="card card-compact bg-base-100 md:w-96 w-full shadow-xl max-w-[100%]">
        <figure>
            <img  class="fixed-size"
                src="@/images/gifs/pvcdirector.gif"
                alt="Price Crawler GIF" />
        </figure>
        <div class="card-body mt-2">
          <h2 class="card-title">Pvc Directo</h2>
          <p>This was one of my first projects, where I was responsible for the development, design, deployment, and migration of email servers.</p>
          <div class="flex lg:flex-row flex-col gap-2">
            <span class="badge badge-bg-color"><i class="fab fa-symfony"></i>&nbsp;Symfony</span>          
            <span class="badge badge-bg-color"><i class="fab fa-css3-alt"></i>&nbsp;CSS</span>
            <span class="badge badge-bg-color"><i class="fab fa-html5"></i>&nbsp;HTML</span>
            <span class="badge badge-bg-color"><i class="fas fa-database"></i>&nbsp;MySQL</span>
          </div>
          <div class="card-actions w-full pt-3 ">
            <button class="btn btn-outline  w-full"  onclick="modal_1.showModal()">See more</button>
          </div>
        </div>
      </div>
 
      <div class="card card-compact bg-base-100 md:w-96 w-full shadow-xl max-w-[100%]">
        <figure>
            <img  class="fixed-size"
            src="@/images/gifs/pricecrawler.gif"
            alt="Album" />
        </figure>
        <div class="card-body mt-2">
          <h2 class="card-title">Price Crawler</h2>
          <p>This web scraping crawler was developed for a client to save them time on price research, allowing them to focus more on strategic decision-making</p>
          <div class="flex lg:flex-row  flex-col gap-1">
            <span class="badge badge-bg-color"><i class="fab fa-symfony"></i>&nbsp;Symfony</span>          
            <span class="badge badge-bg-color"><i class="fab fa-docker"></i>&nbsp;Docker</span>
            <span class="badge badge-bg-color">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                <path d="M12 0C5.373 0 0 5.373 0 12c0 6.627 5.373 12 12 12s12-5.373 12-12C24 5.373 18.627 0 12 0zm0 22.8C5.82 22.8.8 17.78.8 12S5.82 1.2 12 1.2 23.2 6.22 23.2 12 18.18 22.8 12 22.8zM16.5 7.5c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5zm-9 0c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5zm4.5 3c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5zm0 3c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5zm4.5 0c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5z"/>
              </svg>&nbsp;Panther
            </span>
            <span class="badge badge-bg-color"><i class="fas fa-database"></i>&nbsp;MySQL</span>
          </div>
          <div class="card-actions w-full pt-3 ">
            <button class="btn btn-outline  w-full"  onclick="modal_2.showModal()">See more</button>
          </div>
        </div>
      </div>
      
</div>
<div class="flex flex-col sm:flex-row gap-8 my-3 sm:my-8">
    <div class="card card-compact bg-base-100 md:w-96 w-full shadow-xl max-w-[100%]">
        <figure>
            <img  class="fixed-size"
                src="@/images/gifs/cs2smokes.gif"
                alt="Price Crawler GIF" />
        </figure>
        <div class="card-body">
          <h2 class="card-title">cs2smokes</h2>
          <p>Need help with CS:GO smokes? Check out cs2smokes for quick and easy guides and videos to learn useful smoke spots. </p>
          <div class="flex lg:flex-row flex-col gap-2">
            <span class="badge badge-bg-color"><i class="fab fa-symfony"></i>&nbsp;Symfony</span>          
            <span class="badge badge-bg-color"><i class="fab fa-css3-alt"></i>&nbsp;CSS</span>
            <span class="badge badge-bg-color"><i class="fab fa-html5"></i>&nbsp;HTML</span>
            <span class="badge badge-bg-color"><i class="fas fa-database"></i>&nbsp;MySQL</span>
          </div>
          <div class="card-actions w-full pt-3 ">
            <button class="btn btn-outline  w-full"  onclick="modal_3.showModal()">See more</button>
          </div>
        </div>
      </div>
      <div class="card card-compact bg-base-100 md:w-96 w-full shadow-xl max-w-[100%]">
        <figure>
            <img  class="fixed-size"
            src="@/images/gifs/drmartin.gif"
            alt="Album" />
        </figure>
        <div class="card-body">
          <h2 class="card-title">Dr Martin</h2>
          <p>Dr. Martin Clinic: A multilingual WordPress website optimized for SEO and enhanced with visually appealing images.</p>
          <div class="flex lg:flex-row flex-col gap-2">
            <span class="badge badge-bg-color"><i class="fab fa-wordpress"></i>&nbsp;Wordpress</span>          
            <span class="badge badge-bg-color"><i class="fab fa-css3-alt"></i>&nbsp;CSS</span>
            <span class="badge badge-bg-color"><i class="fab fa-html5"></i>&nbsp;HTML</span>
          </div>
          <div class="card-actions w-full pt-3 ">
            <button class="btn btn-outline  w-full"  onclick="modal_4.showModal()">See more</button>
          </div>
        </div>
      </div>
</div>
<div class="flex flex-col sm:flex-row gap-8 my-3 sm:my-8">
  <div class="card card-compact bg-base-100 md:w-96 w-full shadow-xl max-w-[100%]">
      <figure>
          <img  class="fixed-size"
              src="@/images/gifs/portofolio.gif"
              alt="Price Crawler GIF" />
      </figure>
      <div class="card-body">
        <h2 class="card-title">Portofolio</h2>
        <p>Web Developer Portfolio: Showcase my skills and projects. </p>
        <div class="flex lg:flex-row flex-col gap-2">
          <span class="badge badge-bg-color"><i class="fab fa-symfony"></i>&nbsp;Symfony</span>          
          <span class="badge badge-bg-color"><i class="fab fab fa-vuejs"></i>&nbsp;Vue</span>
          <span class="badge badge-bg-color"><i class="fab fa-js"></i>&nbsp;Js</span>
          <span class="badge badge-bg-color"><i class="fas fa-database"></i>&nbsp;MySQL</span>
        </div>
        <div class="card-actions w-full pt-3 ">
          <button class="btn btn-outline  w-full"  onclick="modal_5.showModal()">See more</button>
        </div>
      </div>
    </div>
    <div class="card card-compact bg-base-100 md:w-96 w-full shadow-xl max-w-[100%]">
      <figure>
          <img  class="fixed-size"
          src="@/images/gifs/clientsdashboard.gif"
          alt="Album" />
      </figure>
      <div class="card-body">
        <h2 class="card-title">Dashboard</h2>
        <p>This dashboard was made for the same client has pricecrawler it has an excel export with price comparison has well has a image formatter to optimize their workflow has they were making thousands of images by hand</p>
        <div class="flex lg:flex-row flex-col gap-2">
          <span class="badge badge-bg-color"><i class="fab fa-symfony"></i>&nbsp;Symfony</span>           
          <span class="badge badge-bg-color"><i class="fab fa-css3-alt"></i>&nbsp;CSS</span>
          <span class="badge badge-bg-color"><i class="fab fa-html5"></i>&nbsp;HTML</span>
          <span class="badge badge-bg-color">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
              <path d="M12 0C5.373 0 0 5.373 0 12c0 6.627 5.373 12 12 12s12-5.373 12-12C24 5.373 18.627 0 12 0zm0 22.8C5.82 22.8.8 17.78.8 12S5.82 1.2 12 1.2 23.2 6.22 23.2 12 18.18 22.8 12 22.8zM16.5 7.5c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5zm-9 0c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5zm4.5 3c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5zm0 3c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5zm4.5 0c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5z"/>
            </svg>&nbsp;EasyAdmin
          </span>
        </div>
        <div class="card-actions w-full pt-3 ">
          <button class="btn btn-outline  w-full"  onclick="modal_6.showModal()">See more</button>
        </div>
      </div>
    </div>
</div>


<dialog id="modal_1" class="modal modal-bottom sm:modal-middle">
  <div class="modal-box">
    <div>
      <p>This project was an exciting challenge, built using Symfony for its backend structure and MariaDB for database management, ensuring both reliability and scalability.<br/><br/> The frontend was enhanced using the W3 UI library, which helped create a clean, responsive, and user-friendly interface. The website incorporates several key features, including lazy loading, which optimizes performance by loading content as needed, improving the user experience on pages with large amounts of data.<br/><br/>Additionally, seamless integration with Google Maps added a valuable interactive component, allowing users to easily locate services and further enhancing the site's functionality. Altogether, these elements came together to deliver a highly optimized, feature-rich website for the client.</p> 
    </div>
    <div class="modal-action">
      <form method="dialog">
        <button class="btn">Close</button>
      </form>
    </div>
  </div>
</dialog>

<dialog id="modal_2" class="modal modal-bottom sm:modal-middle">
  <div class="modal-box">
    <div>
      <figure>
        <img  class="fixed-size"
            src="@/images/gifs/pricecrawler.gif"
            alt="Album" />
      </figure>
    </div>
    <div>
      <p class="text">This project was built using Symfony as the foundation, utilizing Panther to manage the web scraping process. The focus was on gathering data from e-commerce websites in the fabric industry, where the client needed to compare prices and conduct competitive analysis. By leveraging asynchronous scraping, the process was optimized for speed, ensuring that large datasets could be handled efficiently.<br/><br/>

        The client specified the websites to be scraped, and I implemented parent selectors with price and discount selectors to extract relevant pricing information. For websites offering discounts, I utilized regex to strip unnecessary characters, allowing for precise comparisons between the standard and discounted prices. Once extracted, the system compared the prices and stored the lowest one in a database for further analysis. This solution not only automated the price comparison process but also provided the client with valuable insights into their competitiveness in the market</p> 
    </div>
    <div class="modal-action">
      <form method="dialog">
        <button class="btn">Close</button>
      </form>
    </div>
  </div>
</dialog>


<dialog id="modal_3" class="modal modal-bottom sm:modal-middle">
  <div class="modal-box">
    <div>
 
        <p>As a new CSGO player, navigating unfamiliar maps can be challenging. cs2smokes is here to help! Our website provides detailed guides and videos on various smoke spots that can give your team a tactical advantage. Learn the best smoke lines, timings, and setups to improve your gameplay and dominate the battlefield.</p><br/>

        <p>Developed with <strong>Twig</strong> and <strong>Tailwind CSS</strong> for a modern and responsive design, cs2smokes is powered by <strong>Symfony</strong> and <strong>EasyAdmin</strong> for a streamlined development experience. The website utilizes <strong>MariaDB/MySQL</strong> for efficient data storage and retrieval.</p><br/>
        
        <p>I independently developed this project using <strong>Git</strong> for version control and <strong>GitHub</strong> for collaboration. The ongoing challenge is to create comprehensive content, including smoke videos for both Terrorists and Counter-Terrorists, which requires significant time and effort.</p>

  
    </div>
    <div class="modal-action">
      <form method="dialog">
        <button class="btn">Close</button>
      </form>
    </div>
  </div>
</dialog>

<dialog id="modal_4" class="modal modal-bottom sm:modal-middle">
  <div class="modal-box">
    <div>
    
        <p><strong>Dr. Martin Clinic</strong> is a multilingual WordPress website optimized for SEO and enhanced with visually appealing images. I redesigned several sections to improve user experience and ensure optimal performance.</p><br/>

        <p>The website seamlessly supports multiple languages, catering to a diverse international audience. It's carefully optimized for search engines, ensuring better visibility and organic traffic. High-quality images are optimized for fast loading times and improved website performance. The website features a custom-designed interface that reflects the professional and modern ethos of Dr. Martin Clinic.</p><br/>
        
        <p>Developed with <strong>WordPress</strong>, <strong>CSS</strong>, and <strong>HTML</strong>, the website offers a flexible and user-friendly platform. By combining these elements, I created a website that effectively showcases Dr. Martin Clinic's services, engages visitors, and drives conversions.</p>
   
    </div>
    <div class="modal-action">
      <form method="dialog">
        <button class="btn">Close</button>
      </form>
    </div>
  </div>
</dialog>
<dialog id="modal_5" class="modal modal-bottom sm:modal-middle">
  <div class="modal-box">
    <div>
    
      <p><strong>Web Developer Portfolio</strong>: Showcase my skills and projects.</p><br/>

      <p>My portfolio was created with the goal of showcasing my projects and providing insights into my professional background. I aimed to create a visually appealing and informative platform that effectively highlights my skills and experiences.</p><br/>

      <p>Through my portfolio, I hope to connect with potential clients and collaborators, demonstrate my capabilities, and share my passion for web development.</p><br/>

      <p><strong>Vue.js</strong> is a progressive JavaScript framework for building user interfaces. <strong>Symfony</strong> is a PHP framework for web applications. <strong>Daisy UI</strong> is a responsive and customizable UI framework.</p><br/>

      <p>By exploring my projects and learning more about my approach to work, you'll gain a better understanding of my abilities and how I can contribute to your team or projects.</p><br/>

    </div>
    <div class="modal-action">
      <form method="dialog">
        <button class="btn">Close</button>
      </form>
    </div>
  </div>
</dialog>

<dialog id="modal_6" class="modal modal-bottom sm:modal-middle">
  <div class="modal-box">
    <div>
    
      <p><strong>Web Developer Portfolio</strong>: Showcase my skills and projects.</p><br/>

      <p>My portfolio was created with the goal of showcasing my projects and providing insights into my professional background. I aimed to create a visually appealing and informative platform that effectively highlights my skills and experiences.</p><br/>

      <p>Through my portfolio, I hope to connect with potential clients and collaborators, demonstrate my capabilities, and share my passion for web development.</p><br/>

      <p><strong>Vue.js</strong> is a progressive JavaScript framework for building user interfaces. <strong>Symfony</strong> is a PHP framework for web applications. <strong>Daisy UI</strong> is a responsive and customizable UI framework.</p><br/>

      <p>By exploring my projects and learning more about my approach to work, you'll gain a better understanding of my abilities and how I can contribute to your team or projects.</p><br/>

    </div>
    <div class="modal-action">
      <form method="dialog">
        <button class="btn">Close</button>
      </form>
    </div>
  </div>
</dialog>

</template>

<script setup>

</script>

<style scoped>
.my-hobbies-image {
    box-shadow: -15px 15px 0px #E4C6BF;
} 
.fixed-size {
    width: 100%; /* Set the desired width */
    height: 200px; /* Set the desired height */
    object-fit: cover; /* Ensure the image covers the area without distortion */
}
.badge-bg-color {
    background-color: #00ca9224;
}

</style>